import { Link as AscendLink } from '@achieve/ascend'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { useEventScope } from 'hooks/useEventScope'

/**
 * A wrapper component that provides analytics tracking functionality to a AscendLink without the need of a href.
 *
 * @component
 * @param {Object} props - The props for the UncrawlableLink component.
 * @param {Object} props.track - The tracking data for the link, including event_type and other relevant information.
 * @param {String} props.href - The destination for the link.
 * @param {String} props.target - The html target attribute
 * @param {ReactNode} props.children - The child elements to be wrapped by the UncrawlableLink component.
 * @returns {ReactNode} A AscendLink component with added analytics tracking.
 */
function UncrawlableLink({ track, children, href, target, ...ascendLinkProps }) {
  // Retrieving AnalyticsContext for dispatching events
  const { fireAndForget } = useTriggerEvents()

  const specificity = useEventScope()

  if (track) {
    track = updateTrackWithListedEvent(track, specificity)
  }

  /**
   * Local click event handler that adds the tracked event to the analytics event queue.
   *
   * @param {Event} event - The click event object.
   * @param {Object} track - The tracking data for the event.
   * @param {Function} onClick - The original click event handler.
   * @returns {void}
   */
  const localClickHandler = (event, track, onClick) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track, onClick),
    })
  }

  return (
    <AscendLink
      {...ascendLinkProps}
      onClick={(event) => localClickHandler(event, track, () => window.open(href, target))}
    >
      {children}
    </AscendLink>
  )
}

export { UncrawlableLink }
