import * as React from 'react'

export function PlayBtn({ title, height = 54, width = 54, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 41"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      {...props}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_6830_3473)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="black"
          fillOpacity="0.3"
        />
        <g filter="url(#filter0_ddd_6830_3473)">
          <path d="M30 19.9947L15 28.655V11.3345L30 19.9947Z" fill="white" />
          <path
            d="M15.75 12.6335L28.5 19.9947L15.75 27.3559V12.6335Z"
            stroke="white"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ddd_6830_3473"
          x="2"
          y="3.33447"
          width="41"
          height="43.3206"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.65" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0784314 0 0 0 0 0.180392 0 0 0 0 0.341176 0 0 0 0.01725 0"
          />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_6830_3473" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.113725 0 0 0 0 0.145098 0 0 0 0 0.184314 0 0 0 0.017 0"
          />
          <feBlend
            mode="multiply"
            in2="effect1_dropShadow_6830_3473"
            result="effect2_dropShadow_6830_3473"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="6.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.113725 0 0 0 0 0.145098 0 0 0 0 0.184314 0 0 0 0.104 0"
          />
          <feBlend
            mode="multiply"
            in2="effect2_dropShadow_6830_3473"
            result="effect3_dropShadow_6830_3473"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_6830_3473"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_6830_3473">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlayBtn
