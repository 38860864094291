import * as React from 'react'
export function APattern({ title, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={2096} height={899} fill="none" {...props}>
      <title>{title}</title>
      <path
        fill="#fff"
        d="m154.66 708.383 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m93.37 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M316.15 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m254.86 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M477.64 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m416.35 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M639.13 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m577.84 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M800.62 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m739.33 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M962.11 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m900.81 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39L885 804.793M115.95 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m54.66 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M277.44 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m216.15 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M438.93 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m377.64 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M600.42 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m539.13 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M761.91 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m700.62 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M923.4 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m862.11 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1123.4 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1062.11 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1284.89 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1223.6 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1446.38 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1385.08 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1607.86 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1546.57 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1769.35 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1708.06 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1930.84 708.383l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1869.55 850.723 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1084.69 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1023.4 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1246.18 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1184.89 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1407.67 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1346.38 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1569.16 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1507.86 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1730.64 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1669.35 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1892.13 423.443l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1830.84 565.783 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M77.11 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m15.82 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39L0 235.033M238.6 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m177.31 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M400.09 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m338.8 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M561.58 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.16-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m500.29 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M723.07 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43L767.2 2.463c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m661.78 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M884.56 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.16-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m823.27 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1045.85 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.16-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m984.56 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1207.34 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.16-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1146.05 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1368.83 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.16-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39L1353 92.693"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1307.53 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1530.31 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1469.02 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1691.8 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1630.51 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16M1853.29 138.633l87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.15-53.89c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
      <path
        fill="#fff"
        d="m1792 280.973 87.53-30.13c-12.11 17.1-24.85 35.67-28.42 42.98-5.88 12.06-.87 26.6 11.19 32.49 12.06 5.88 26.6.87 32.49-11.19l61.5-116.43-120.14-53.88c-12.06-5.88-26.6-.87-32.49 11.19-5.88 12.06-.87 26.6 11.19 32.49 7.32 3.57 28.84 10.37 48.93 16.39l-87.59 30.16"
        style={{
          mixBlendMode: 'soft-light',
        }}
      />
    </svg>
  )
}
export default APattern
