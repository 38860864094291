import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import {
  ResponsiveTypography as AscendResponsiveTypography,
  Typography as AscendTypography,
  Link as AscendLink,
  Divider as AscendDivider,
  Table as AscendTable,
  TableContainer as AscendTableContainer,
  TableRow as AscendTableRow,
  TableCell as AscendTableCell,
  TableHead as AscendTableHead,
  TableBody as AscendTableBody,
  Paper as AscendPaper,
} from '@achieve/ascend'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { MediaImageStandard, SuperScript } from 'components/Contentful'
import { VideoDialog } from 'components/VideoDialog'
import { get as _get, cloneDeep as _cloneDeep } from 'lodash-es'
import { BlockQuote } from 'components/BlockQuote'
import styles from './Typography.module.scss'
import { isExternal, rewriteAssetUrl } from 'utils/conversions'
import { useCurrentUrl } from 'hooks/useCurrentUrl'
import { UncrawlableLink } from 'components/UncrawlableLink'

/**
 * Contentful RichText places empty paragraph after elements
 * like headings and lists if they are last item in the RichText box
 * @param {Array} content
 * @returns {Array} filtered content
 */

const replaceInlineEntries = (obj1, obj2) => {
  if (obj1?.content?.length) {
    return { ...obj1, content: obj1.content.map((subItem) => replaceInlineEntries(subItem, obj2)) }
  }
  for (const inlineItem of obj2.entries.inline) {
    if (
      inlineItem?.sys?.id === obj1?.data?.target?.sys?.id ||
      inlineItem?.id === obj1?.data?.target?.sys?.id
    ) {
      return { ...obj1, data: { ...inlineItem, id: inlineItem?.sys?.id } }
    }
  }
  return obj1
}

const replaceInlinHyperLink = (obj1, obj2) => {
  if (obj1?.data?.target?.sys?.id) {
    for (const inlineHyperlink of obj2.assets.hyperlink) {
      if (
        inlineHyperlink?.sys?.id === obj1?.data?.target?.sys?.id ||
        inlineHyperlink?.id === obj1?.data?.target?.sys?.id
      ) {
        return { ...obj1, data: { ...inlineHyperlink, id: inlineHyperlink?.sys?.id } }
      }
    }
  }
  if (obj1?.content?.length) {
    return { ...obj1, content: obj1.content.map((subItem) => replaceInlinHyperLink(subItem, obj2)) }
  }
  return obj1
}

const UncrawlableLinkTypography = ({ node, styleOverride, variantOverride }) => {
  const { linkHref, linkText, target } = _get(node, 'data.target.fields', {})
  return (
    <UncrawlableLink
      href={linkHref}
      target={target}
      track={{
        list_name: 'Uncrawlable Link',
        click_text: `Achieve-Web | ${linkHref}`,
        click_type: 'Uncrawlable Link Click',
        track_event: 'ui_click',
        href: linkHref,
      }}
      sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx')}
      variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant')}
      data-testid={`Uncrawlable-Link-${linkText}`}
      component="button"
      className={styles['typography-hyperlink']}
    >
      {linkText}
    </UncrawlableLink>
  )
}

/**
 * Checks if a given node is an empty text node.
 * An empty text node is defined as a node that:
 * - Has a `nodeType` property of type 'string' that matches the word "paragraph" (case-insensitive).
 * - Contains only one item in its `content` array.
 * - The single item in the `content` array is of type 'text'.
 * - The value of the single 'text' type item is an empty string.
 *
 * @param {Object} node - The node to check.
 * @returns {boolean} - Returns `true` if the node is an empty text node, otherwise `false`.
 */
const isEmpyTextNode = (node) => {
  return (
    typeof node.nodeType === 'string' &&
    node.nodeType.match(/paragraph/i) &&
    node.content.length === 1 &&
    node.content[0].nodeType === 'text' &&
    node.content[0].value === ''
  )
}

const removeTrailingReturnChar = (content) => {
  if (!Array.isArray(content)) {
    // Gracefully fail
    return content
  }
  if (content.length <= 1) {
    return content
  }
  const lastItem = content[content.length - 1]
  const prevLastItem = content[content.length - 2]
  //Only do the return char removal if the last item is an empty text node and the previous one is not, this is to avoid hydration error.
  if (isEmpyTextNode(lastItem) && !isEmpyTextNode(prevLastItem)) {
    return content.slice(0, -1)
  }
  return content
}

/**
 * Always returns textContent with content array as a prop in the shape
 * @param {Object} content
 * @returns {Object} textContent: {content: []}
 */
const handleContentToMapper = (content = {}) => {
  let textContent = {}
  if (Object.hasOwnProperty.call(content || {}, 'json')) {
    if (content?.links?.entries?.inline?.length) {
      textContent = replaceInlineEntries(content.json, content.links)
    } else if (content?.links?.assets?.hyperlink?.length) {
      textContent = replaceInlinHyperLink(content.json, content.links)
    } else {
      textContent = content.json
    }
  }
  if (Object.hasOwnProperty.call(content || {}, 'fields')) {
    textContent = content.fields.textContent
  } else if (Object.hasOwnProperty.call(content || {}, 'nodeType')) {
    textContent = content
  }
  textContent.content = removeTrailingReturnChar(textContent.content)

  return { textContent }
}

const RICH_TEXT = {
  [BLOCKS.PARAGRAPH]: { variant: 'bodyLg', component: 'p' },
  [BLOCKS.HEADING_1]: { variant: 'displayXl', component: 'h1' },
  [BLOCKS.HEADING_2]: { variant: 'displayXl', component: 'h2' },
  [BLOCKS.HEADING_3]: { variant: 'displayXl', component: 'h3' },
  [BLOCKS.HEADING_4]: { variant: 'displayXl', component: 'h4' },
  [BLOCKS.HEADING_5]: { variant: 'displayLg', component: 'h5' },
  [BLOCKS.HEADING_6]: { variant: 'headingLg', component: 'h6' },
  [BLOCKS.OL_LIST]: { component: 'ol' },
  [BLOCKS.UL_LIST]: { component: 'ul' },
  [BLOCKS.LIST_ITEM]: { component: 'li' }, //sx properties apply to single child element
  [BLOCKS.HR]: {},
  [BLOCKS.EMBEDDED_ASSET]: {},
  [BLOCKS.TABLE]: { variant: 'bodyLg', component: 'table' },
  [BLOCKS.TABLE_ROW]: { variant: 'bodyLg', component: 'tr' },
  [BLOCKS.TABLE_CELL]: { variant: 'bodyLg', component: 'td' },
  [BLOCKS.TABLE_HEADER_CELL]: { variant: 'bodyLg', component: 'th' },
  [INLINES.HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  [INLINES.ASSET_HYPERLINK]: { component: 'a' }, // inlines should inherit the font variant
  // strong is semantic bold
  [MARKS.BOLD]: { variant: 'bodyLg', component: 'strong' },
  // Currently unimplemented in Ascend
  //[MARKS.CODE]: {component: 'code'},
  //[BLOCKS.QUOTE]:{},
}
/**
 * Returns the overridden value for a given rich text enum, or the default value from the RICH_TEXT
 * object.
 *
 * @param {string} richTextEnum - The rich text enum to check for an override.
 * @param {object} overrideObject - The object containing the overrides for the rich text enums.
 * @param {string} key - The key to retrieve the value from the rich text enum or override object.
 * @param {object} richText - The RICH_TEXT object containing the default values for the rich text
 * enums.
 * @returns {string} - The overridden value for the rich text enum, or the default value from the
 * RICH_TEXT object.
 */
const selectOverride = (richTextEnum, overrideObject, key, richText = RICH_TEXT) => {
  return typeof overrideObject === 'object' &&
    Object.hasOwnProperty.call(overrideObject, richTextEnum)
    ? overrideObject[richTextEnum]
    : richText[richTextEnum][key]
}

const parseRichTextLineBreak = (children) => {
  if (Array.isArray(children) && children.length === 1 && children[0] === '') {
    return ['\u00A0']
  }

  function splitBreakLineInline(inputArray) {
    if (!Array.isArray(inputArray)) return inputArray

    let resultArray = []
    inputArray.forEach((item) => {
      if (typeof item === 'string') {
        if (item?.includes('\n')) {
          const splitItems = item.split('\n')
          splitItems.forEach((splitItem, index) => {
            resultArray.push(splitItem)
            if (index < splitItems.length - 1) {
              resultArray.push(<br />)
            }
          })
        } else {
          resultArray.push(item)
        }
      } else {
        resultArray.push(item)
      }
    })

    return resultArray
  }

  return splitBreakLineInline(children).map((child) =>
    typeof child === 'string' ? child.replaceAll(/[\u2028\u2029]/g, '') : child
  )
}

const TypographyStringType = ({
  styleOverride,
  variantOverride,
  mobileVariantOverride,
  typographyContent,
  richText = RICH_TEXT,
  breakpoint,
  responsive,
  props,
}) => {
  return responsive ? (
    <AscendResponsiveTypography
      sx={selectOverride(BLOCKS.PARAGRAPH, styleOverride, 'sx')}
      variant={selectOverride(BLOCKS.PARAGRAPH, variantOverride, 'variant')}
      mobileVariant={selectOverride(BLOCKS.PARAGRAPH, mobileVariantOverride, 'mobileVariant')}
      breakpoint={breakpoint}
      component={richText[BLOCKS.PARAGRAPH].component}
      {...props}
    >
      {typographyContent}
    </AscendResponsiveTypography>
  ) : (
    <AscendTypography
      sx={selectOverride(BLOCKS.PARAGRAPH, styleOverride, 'sx')}
      variant={selectOverride(BLOCKS.PARAGRAPH, variantOverride, 'variant')}
      component={richText[BLOCKS.PARAGRAPH].component}
      {...props}
    >
      {typographyContent}
    </AscendTypography>
  )
}

/**
 * Create renderNode options for BLOCKS that return similar/identical elements (headers and lists)
 * @param {Object} node      //Is provided by documentToReactComponents
 * @param {Object} children  //Is provided by documentToReactComponents
 * @param {String} blockType      //BLOCKTYPE allowed by documentToReactComponents
 * @param {Boolean} variantToChild //true if variant override should be applied to child component
 * @param {Boolean} styleToChild   //true if style override should be applied to child component
 * @returns {JSX} <JSX element to be displayed >
 */
function createRenderNodeDefault({
  children,
  blockType,
  responsive,
  mobileVariant,
  breakpoint,
  variantToChild = false,
  styleToChild = false,
  variantOverride,
  mobileVariantOverride,
  styleOverride,
  className,
  richText = RICH_TEXT,
  propsComponent,
}) {
  //Logic to pass variant and style to children (uses: ie. BLOCK.LIST_ITEM which have nested <p>)
  let childWithParentProps = children
  if (variantToChild || styleToChild) {
    childWithParentProps = _cloneDeep(children)
    if (variantToChild) {
      //Pass block variant to child
      childWithParentProps.forEach((child) => {
        if (Object.hasOwnProperty.call(child, 'props'))
          child.props.variant = selectOverride(
            BLOCKS[blockType],
            variantOverride,
            'variant',
            richText
          )
        if (responsive) {
          child.props.breakpoint = breakpoint
          child.props.mobileVariant = selectOverride(
            BLOCKS[blockType],
            mobileVariantOverride,
            'mobileVariant',
            richText
          )
        }
      })
    }
    if (styleToChild) {
      //Pass block style to child
      childWithParentProps.forEach((child) => {
        if (Object.hasOwnProperty.call(child, 'props'))
          child.props.sx = selectOverride(BLOCKS[blockType], styleOverride, 'sx', richText)
      })
    }
  }
  const classNames = [className, propsComponent.className].join(' ')

  return responsive ? (
    <AscendResponsiveTypography
      sx={selectOverride(BLOCKS[blockType], styleOverride, 'sx')}
      variant={selectOverride(BLOCKS[blockType], variantOverride, 'variant')}
      mobileVariant={mobileVariant}
      breakpoint={breakpoint}
      component={richText[BLOCKS[blockType]].component}
      {...propsComponent}
    >
      {parseRichTextLineBreak(childWithParentProps)}
    </AscendResponsiveTypography>
  ) : (
    <AscendTypography
      sx={selectOverride(BLOCKS[blockType], styleOverride, 'sx', richText)}
      variant={selectOverride(BLOCKS[blockType], variantOverride, 'variant', richText)}
      component={richText[BLOCKS[blockType]].component}
      {...propsComponent}
      className={classNames}
    >
      {parseRichTextLineBreak(childWithParentProps)}
    </AscendTypography>
  )
}

function optionsDefault({
  createRenderNode,
  props,
  styleOverride,
  variantOverride,
  richText = RICH_TEXT,
  stylesNodes = styles,
}) {
  return {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong {...props}>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em {...props}>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => createRenderNode(node, children, 'PARAGRAPH'),
      [BLOCKS.HEADING_1]: (node, children) => createRenderNode(node, children, 'HEADING_1'),
      [BLOCKS.HEADING_2]: (node, children) => createRenderNode(node, children, 'HEADING_2'),
      [BLOCKS.HEADING_3]: (node, children) => createRenderNode(node, children, 'HEADING_3'),
      [BLOCKS.HEADING_4]: (node, children) => createRenderNode(node, children, 'HEADING_4'),
      [BLOCKS.HEADING_5]: (node, children) => createRenderNode(node, children, 'HEADING_5'),
      [BLOCKS.HEADING_6]: (node, children) => createRenderNode(node, children, 'HEADING_6'),
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className={stylesNodes['typography-ol']}>{children}</ol>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className={stylesNodes['typography-ul']}>{children}</ul>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className={stylesNodes['typography-li']}>{children}</li>
      },
      [BLOCKS.HR]: () => {
        return (
          <AscendDivider
            className={stylesNodes['typography-hr']}
            sx={selectOverride(BLOCKS.HR, styleOverride, 'sx', richText)}
            {...props}
          />
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageWidth = _get(node, 'data.target.fields.file.details.image.width')
        const imageHeight = _get(node, 'data.target.fields.file.details.image.height')

        return (
          <span className={styles['img-container']}>
            <MediaImageStandard
              content={node?.data.target}
              width={imageWidth}
              height={imageHeight}
              className={styles['responsive-image']}
              {...props}
            />
            <p></p>
          </span>
        )
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div>
            <AscendTableContainer
              component={AscendPaper}
              className={`${stylesNodes['typography-table']} ${
                node?.content[0]?.content?.length < 4 && stylesNodes['typography-table-full']
              }`}
            >
              <AscendTable
                size="small"
                sx={selectOverride(BLOCKS.TABLE, styleOverride, 'sx', richText)}
                variant={selectOverride(BLOCKS.TABLE, variantOverride, 'variant', richText)}
                {...props}
              >
                {children}
              </AscendTable>
            </AscendTableContainer>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const childrenNodeTypes = node?.content?.map((c) => c?.nodeType) ?? []
        if (childrenNodeTypes.includes(BLOCKS.TABLE_HEADER_CELL)) {
          return (
            <AscendTableHead>
              <AscendTableRow>{children}</AscendTableRow>
            </AscendTableHead>
          )
        }
        return (
          <AscendTableBody>
            <AscendTableRow>{children}</AscendTableRow>
          </AscendTableBody>
        )
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return (
          <AscendTableCell className={stylesNodes['typography-cell']}>{children}</AscendTableCell>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return (
          <AscendTableCell className={stylesNodes['typography-cell']}>{children}</AscendTableCell>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <HYPERLINK node={node} props={props} styleOverride={styleOverride} variantOverride>
            {children}
          </HYPERLINK>
        )
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const linkId = node?.content[0]?.value
        const url = rewriteAssetUrl(node?.data?.target?.fields?.file.url || node?.data.url)
        return (
          <AscendLink
            target="_blank"
            sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', richText)}
            variant={selectOverride(INLINES.ASSET_HYPERLINK, variantOverride, 'variant', richText)}
            data-testid={`Link-${url}-${linkId}`}
            href={rewriteAssetUrl(url)}
            aria-label={`${children} - link opens in a new tab`}
            {...props}
            component={richText[INLINES.ASSET_HYPERLINK].component}
          >
            {children}
          </AscendLink>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        // TODO: Handle other types of inline entries. Currently,
        //       node is a data object with no content. Rendering in
        //       an html tag does not work, invalid React child

        const { plainText, styledText, variation, identifier, videoPlayer } = _get(
          node?.data?.target?.fields ? node.data.target.fields : node,
          'data',
          {}
        )
        const { contentType } = _get(node, 'data.target.sys', {})
        if (styledText === 'superscript') {
          return <SuperScript text={plainText} variation={variation} {...props} />
        }
        if (styledText === 'scrollTo') {
          return <span data-scrolto={identifier}></span>
        }
        if (styledText === 'scrollFrom') {
          return (
            <AscendLink
              sx={selectOverride(INLINES.ASSET_HYPERLINK, styleOverride, 'sx', richText)}
              variant={selectOverride(
                INLINES.ASSET_HYPERLINK,
                variantOverride,
                'variant',
                richText
              )}
              data-testid={`Scroll-to-${plainText}`}
              onClick={() => {
                const el = document.querySelector(`[data-scrolto="${identifier}"]`)
                if (el) {
                  // Adding -70px to fix element padding
                  window.scrollTo({
                    top: el.offsetTop - 70,
                    behavior: 'smooth',
                  })
                }
              }}
              aria-label={`Scroll to ${plainText}`}
              {...props}
              component={richText[INLINES.ASSET_HYPERLINK].component}
            >
              {plainText}
            </AscendLink>
          )
        }
        if (videoPlayer && node?.data?.__typename === 'VideoModal') {
          // return <VideoDialog content={node?.data} />
          const target = _get(node, 'data', {})
          return <VideoDialog content={target} />
        }

        if (contentType?.sys?.id === 'uncrawlableLink') {
          return (
            <UncrawlableLinkTypography
              node={node}
              styleOverride={styleOverride}
              variantOverride={variantOverride}
            />
          )
        }

        return null
      },
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div>
            <AscendTableContainer
              component={AscendPaper}
              className={`${stylesNodes['typography-table']} ${
                node?.content[0]?.content?.length < 4 && stylesNodes['typography-table-full']
              }`}
            >
              <AscendTable
                size="small"
                sx={selectOverride(BLOCKS.TABLE, styleOverride, 'sx', richText)}
                variant={selectOverride(BLOCKS.TABLE, variantOverride, 'variant', richText)}
                {...props}
              >
                {children}
              </AscendTable>
            </AscendTableContainer>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => {
        const childrenNodeTypes = node?.content?.map((c) => c?.nodeType) ?? []
        if (childrenNodeTypes.includes(BLOCKS.TABLE_HEADER_CELL)) {
          return (
            <AscendTableHead>
              <AscendTableRow>{children}</AscendTableRow>
            </AscendTableHead>
          )
        }
        return (
          <AscendTableBody>
            <AscendTableRow>{children}</AscendTableRow>
          </AscendTableBody>
        )
      },
      [BLOCKS.TABLE_CELL]: (node, children) => {
        return (
          <AscendTableCell className={stylesNodes['typography-cell']}>{children}</AscendTableCell>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        return (
          <AscendTableCell className={stylesNodes['typography-cell']}>{children}</AscendTableCell>
        )
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <BlockQuote text={node.content}>{children}</BlockQuote>
      },
    },

    renderText: (text) => text,
  }
}

/**
 * Render Contentful Typography components from Contentful Rich Text
 * To build the variantOverride object you will need to import import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
 * @param {Object} content
 * @param {Object} variantOverride object of rich-text-types as keys and Ascend Variants as the value
 * @param {Object} styleOverride object of rich-text-types as keys and a MUI "sx" object as the value
 * @param {String} mobileVariant object of rich-text-types as keys and a MUI "sx" object as the value
 * @param {String} breakpoint object of rich-text-types as keys and a MUI "sx" object as the value
 * @param {Boolean} responsive determines to use standard typography or responsive
 * @returns
 */
function Typography({
  content: typographyContent = '',
  variantOverride,
  mobileVariantOverride,
  styleOverride,
  mobileVariant,
  breakpoint,
  responsive,
  ...props
}) {
  if (typeof typographyContent === 'string') {
    return TypographyStringType({
      styleOverride,
      variantOverride,
      mobileVariantOverride,
      typographyContent,
      mobileVariant,
      breakpoint,
      responsive,
      props,
    })
  }
  // otherwise must be an object
  const { textContent } = handleContentToMapper(typographyContent)

  const createRenderNode = (
    node,
    children,
    blockType,
    className = 'typography-default',
    variantToChild = false,
    styleToChild = false
  ) => {
    const dataTestId = _get(node, 'content[0].value')
      ? `${_get(node, 'content[0].value')}`.slice(0, 50)
      : 'no-id-needed'
    const propsComponent = {
      ['data-testid']: dataTestId,
      ...props,
    }

    return createRenderNodeDefault({
      children,
      blockType,
      variantToChild,
      styleToChild,
      variantOverride,
      mobileVariantOverride,
      styleOverride,
      className,
      mobileVariant,
      breakpoint,
      responsive,
      propsComponent,
    })
  }

  const options = optionsDefault({ createRenderNode, props, styleOverride, variantOverride })

  return textContent?.nodeType || textContent?.data
    ? documentToReactComponents(textContent || [], options)
    : null
}

function HYPERLINK({ node, children, props, styleOverride, variantOverride }) {
  const currentURL = useCurrentUrl()
  const linkId = node?.content[0].value
  const url = node?.data.uri
  return (
    <AchieveLink
      noLink
      track={{
        list_name: 'Inline Link',
        click_id: linkId,
        click_text: `Achieve-Web | ${linkId}`,
        click_position: '0',
        click_type: 'Button Click',
        nav_link_section: 'CTA Card - In-Content',
        track_event: 'page_nav_click',
      }}
      sx={selectOverride(INLINES.HYPERLINK, styleOverride, 'sx')}
      variant={selectOverride(INLINES.HYPERLINK, variantOverride, 'variant')}
      data-testid={`Link-${url}-${linkId}`}
      href={url}
      target={isExternal(url, currentURL) ? '_blank' : '_self'}
      {...props}
      component={RICH_TEXT[INLINES.HYPERLINK].component}
    >
      {children[0] || children}
    </AchieveLink>
  )
}

export default Typography
export {
  Typography,
  removeTrailingReturnChar,
  RICH_TEXT,
  handleContentToMapper,
  selectOverride,
  HYPERLINK,
  parseRichTextLineBreak,
  TypographyStringType,
  createRenderNodeDefault,
  optionsDefault,
  UncrawlableLinkTypography,
}
