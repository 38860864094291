import { Typography } from 'components/Contentful'
import styles from './BorderTextBox.module.scss'

export const BorderTextBox = ({ content, variant, id }) => {
  return (
    <div className={styles[`container`]} id={id}>
      <Typography content={content} variant={variant ? variant : 'bodyXs'} fontWeight="regular" />
    </div>
  )
}
