import {
  isGetStartedRoute,
  isResolveApplyRoute,
  isDirectMailerRoute,
} from 'utils/validations/isRoutes'
import { getVisitId } from '@achieve/cx-event-horizon/browser'
import { isWindowReady } from 'utils/viewport'

/**
 * Adds UTM parameters to a link url if it matches any of the routes in the condition
 *
 * @param {string} memoHref - The memo href to add UTM parameters to.
 * @returns {string} The memo href with added UTM parameters.
 */
const getProductAndAddDefaultUtmsToLink = (memoHref) => {
  // If the memo href is a get started link, resolve-debt/apply or a direct mailer, add UTM parameters.
  let parsedHref = memoHref?.trim()
  if (
    isGetStartedRoute(parsedHref?.replace('/', '').substring(0, 11)) ||
    isResolveApplyRoute(parsedHref?.replace('/', '').substring(0, 18)) ||
    isDirectMailerRoute(parsedHref)
  ) {
    parsedHref = addDefaultOrganicParams(parsedHref)

    // Add utm_source parameter if it does not exist
    if (parsedHref.indexOf('utm_source') === -1) {
      let product
      product = parsedHref
        ?.split('?')[1]
        ?.split('&')
        ?.filter((qp) => qp.split('=')[0] == 'section')[0]
        ?.split('=')[1]
        .split('_')[1]
      parsedHref = `${parsedHref}&utm_source=achieve_organic_${product}`
    }
  }

  return parsedHref
}

/**
 * Adds default UTM parameters to a link url
 *
 * @param {string} memoHref - The memo href to add UTM parameters to.
 * @returns {string} The memo href with added UTM parameters.
 */
const addDefaultOrganicParams = (memoHref) => {
  const hasExistingParams = Boolean(memoHref.indexOf('?') !== -1)

  if (memoHref.indexOf('utm_medium') === -1) {
    memoHref = `${memoHref}${hasExistingParams ? '&' : '?'}utm_medium=organic`
  }

  // Add utm_media parameter if it does not exist or if utm_term also exists.
  if (memoHref.indexOf('utm_media') === -1 && memoHref.indexOf('utm_term') === -1) {
    memoHref = `${memoHref}&utm_media=organic`
  }

  // Add visitId
  memoHref = addVisitIdToLink(memoHref)

  return memoHref
}

const addVisitIdToLink = (memoHref) => {
  let visitId = ''

  // getVisitId() only works in the client therefore we need to check if window is defined
  if (isWindowReady()) {
    visitId = getVisitId()
  }
  if (memoHref.indexOf('visit_id') === -1 && visitId) {
    memoHref = `${memoHref}&visit_id=${visitId}`
  }

  return memoHref
}

export { addDefaultOrganicParams, getProductAndAddDefaultUtmsToLink }
