import { Image } from 'components/Image'
import { MediaImageStandard } from 'components/Contentful'

const CENTER_TOP = 'centerTop'
const RIGHT_TOP = 'rightTop'
const RIGHT_CENTER = 'rightCenter'
const RIGHT_BOTTOM = 'rightBottom'
const CENTER_BOTTOM = 'centerBottom'
const LEFT_BOTTOM = 'leftBottom'
const LEFT_CENTER = 'leftCenter'
const LEFT_TOP = 'leftTop'

const ANCHOR_POINTS = {
  CENTER_TOP,
  RIGHT_TOP,
  RIGHT_CENTER,
  RIGHT_BOTTOM,
  CENTER_BOTTOM,
  LEFT_BOTTOM,
  LEFT_CENTER,
  LEFT_TOP,
}

function AchieveBadge({
  alt = 'Made for you by Achieve badge',
  anchor = CENTER_TOP,
  content,
  height,
  priority = false,
  translateX = 0,
  translateY = 0,
  width,
}) {
  const ANCHORS = {
    centerTop: {
      top: 0,
      baseX: '0',
      baseY: '-50%',
    },
    rightTop: {
      top: 0,
      right: 0,
      baseX: '50%',
      baseY: '-50%',
    },
    rightCenter: {
      right: 0,
      baseX: '50%',
      baseY: '0',
    },
    rightBottom: {
      right: 0,
      bottom: 0,
      baseX: '50%',
      baseY: '50%',
    },
    centerBottom: {
      bottom: 0,
      baseX: '0',
      baseY: '50%',
    },
    leftBottom: {
      bottom: 0,
      left: 0,
      baseX: '-50%',
      baseY: '50%',
    },
    leftCenter: {
      left: 0,
      baseX: '-50%',
      baseY: '0',
    },
    leftTop: {
      left: 0,
      top: 0,
      baseX: '-50%',
      baseY: '-50%',
    },
  }

  const { baseX, baseY, ...restPosition } = ANCHORS[anchor]
  let positionX = translateX !== 0 ? translatePositionX(baseX, translateX) : baseX
  let positionY = translateY !== 0 ? translatePositionY(baseY, translateY) : baseY

  const finalTransform = `translate(${positionX}, ${positionY})`

  const positionStyles = {
    transform: finalTransform,
    ...restPosition,
  }

  return (
    <div style={{ position: 'absolute', zIndex: '1', ...positionStyles }}>
      {content ? (
        <MediaImageStandard
          alt={alt}
          height={height}
          priority={priority}
          content={content}
          width={width}
          layout="fill"
        />
      ) : (
        <Image
          alt={alt}
          height={height}
          priority={priority}
          src="/badge-1.svg" // use default Achieve badge
          width={width}
        />
      )}
    </div>
  )
}

function translatePositionX(baseX, translateX) {
  if (baseX === '0') {
    return !translateX ? baseX : `${translateX}px`
  }

  return `calc(${baseX} + ${translateX}px)`
}

function translatePositionY(baseY, translateY) {
  if (baseY === '0') {
    return !translateY ? baseY : `${translateY}px`
  }

  return `calc(${baseY} + ${translateY}px)`
}

export { AchieveBadge, ANCHOR_POINTS }
