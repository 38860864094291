import { Typography } from 'components/Contentful'
import useViewportSize from 'hooks/useViewportSize'

// Matches `Strict Rich Text` fields from Contentful
function TypographyStrict({ content, ...props }) {
  const { breakpointLetterSize } = useViewportSize()
  const { textContent, component } = content

  return (
    <Typography
      {...props}
      content={textContent}
      component={component}
      variant={content[`variant_${breakpointLetterSize}`]}
      fontWeight={content[`weight_${breakpointLetterSize}`]}
    />
  )
}

export { TypographyStrict }
