/**
 * Description of getJsonBreadCrumbList.
 * @param {Object[]}    list   Array of Objects with id and name params.
 * @returns {Object[]}
 * Should return an array of objects like this:
 * {
 *   '@type': 'ListItem',
 *   item: {
 *          '@id': '/dollars-sense/everyday-finances',
 *          name: 'Everyday Finances',
 *          },
 *   position: 2,
 * }
 */

export const getJsonBreadCrumbList = (list = []) => {
  return list.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.id,
        name: item.name,
      },
    }
  })
}
