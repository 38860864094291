import styles from './Card.module.css'
import { Typography, MediaImageStandard, Link } from 'components/Contentful'

export const Card = ({ content }) => {
  if (content === undefined || Object.keys(content).length === 0) return null
  const { cardTitleText, cardText, cardLink, cardImage } = content?.fields || {}

  return (
    <>
      {cardImage && <MediaImageStandard content={cardImage} />}
      <div className={styles.spacer}></div>
      <div className={styles.text}>
        <div>
          {cardTitleText && <Typography content={cardTitleText} />}
          {cardText && <Typography content={cardText} />}
        </div>
        {cardLink && <Link content={cardLink} />}
      </div>
    </>
  )
}
