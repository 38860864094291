import styles from 'components/Cards/CardProduct/CardProduct.module.scss'
import { Typography, MediaImageStandard, LinkButton } from 'components/Contentful'
import { Paper, Button } from '@achieve/ascend'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import { isEmpty as _isEmpty } from 'lodash-es'
import { useRouter } from 'next/router'
import { removePageFromQuery } from 'utils/next/routes'
import { LINK_UI_COMPONENTS } from 'constants/contentfulUiComponentTypes'
import { rewriteAssetUrl } from 'utils/conversions/urls'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'

export const CardProduct = ({ content, index }) => {
  const router = useRouter()
  const {
    cardTitleText,
    cardSubtitleText,
    cardText,
    cardLink,
    secondaryCardLink,
    cardImage,
    cardBadgeImage,
    iconCardItem,
    eyebrow,
    cardJson,
  } = useInlineMemoizedContentGetter(content, [
    'cardTitleText',
    'cardSubtitleText',
    'cardText',
    'cardLink',
    'secondaryCardLink',
    'cardImage',
    'cardBadgeImage',
    'iconCardItem',
    'eyebrow',
    'cardJson',
  ])
  if (_isEmpty(content)) return null

  const cardTitleString = cardTitleText?.json?.content?.[0]?.content[0]?.value

  const linkOrDownload = (linkContent) => {
    if (!linkContent?.__typename) return
    if (linkContent?.__typename == 'DownloableAttachment') {
      const assetUrl = rewriteAssetUrl(linkContent.downloadFile.url)

      linkContent = {
        linkText: linkContent.description?.json?.content[0],
        linkHref: assetUrl,
      }
      return (
        <Button
          className={styles['button']}
          track={{
            ...{
              list_name: 'Product Cards Download',
            },
            ...linkContent.event,
          }}
          variant="contained"
        >
          <a
            className={styles['a']}
            href={linkContent.linkHref}
            download
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              content={linkContent.linkText}
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodyLg' }}
              fontWeight="bold"
            ></Typography>
          </a>
        </Button>
      )
    }

    if (linkContent?.fieldName == 'goToPressKit') {
      return (
        <Button className={styles['button']} variant="contained">
          <a
            className={styles['a']}
            href={`${linkContent.linkHref}${
              Object.keys(router.query).length === 0 ? '' : `?${removePageFromQuery(router.query)}`
            }`}
          >
            <Typography
              content={linkContent.name}
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodyLg' }}
              fontWeight="bold"
            ></Typography>
          </a>
        </Button>
      )
    }

    const textButtonTrack =
      linkContent?.linkText?.json?.content[0]?.content[0]?.value ?? 'product cards'

    return (
      <LinkButton
        className={styles['button']}
        track={{
          ...{
            list_name: 'Recommended Card Link',
            click_position: index >= 0 ? index + 1 : '0',
            click_id: textButtonTrack,
            click_text: textButtonTrack,
          },
          ...linkContent?.fields?.event,
        }}
        content={{ linkText: linkContent?.linkText?.json, linkHref: linkContent.linkHref }}
        typographicOptions={{
          variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
          fontWeight: 'bold',
        }}
        color="primary"
        variant={
          linkContent?.uiComponent === LINK_UI_COMPONENTS.LINK
            ? LinkButton.VARIANTS.TEXT
            : LinkButton.VARIANTS.CONTAINED
        }
        fullWidth={true}
        data-testid={`home-product-cards-section-card-button-text-${cardTitleString}`}
      />
    )
  }

  const defaultBadgeProps = {
    width: 90,
    height: 90,
    anchor: ANCHOR_POINTS.RIGHT_TOP,
    translateX: -90,
  }

  // Spread any CMS specified badge props over default badge props
  const badgeProps = {
    ...defaultBadgeProps,
    ...(cardJson?.achieveBadge || {}),
  }

  return (
    <Paper
      elevation={0}
      className={styles['card']}
      data-testid={`home-product-cards-section-card-${cardTitleString}`}
      data-paper={cardTitleString}
      data-with-badge={Boolean(cardBadgeImage)}
    >
      {cardBadgeImage && <AchieveBadge content={cardBadgeImage} {...badgeProps} />}
      {cardImage && (
        <div className={styles['card-image']}>
          <MediaImageStandard
            content={cardImage}
            width={60}
            height={60}
            layout="raw"
            data-testid={`home-product-cards-section-card-image-${cardTitleString}`}
          />
        </div>
      )}
      {eyebrow && <Eyebrow className={styles['card-eyebrow']} content={eyebrow} />}
      {cardTitleText && (
        <Typography
          content={cardTitleText}
          variantOverride={{ [BLOCKS.HEADING_3]: 'headingMd' }}
          fontWeight="medium"
          data-testid={`home-product-cards-section-card-title-${cardTitleString}`}
        />
      )}
      {cardSubtitleText && (
        <Typography
          content={cardSubtitleText}
          variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodySm' }}
          className={styles['card-subtitle']}
          fontWeight="regular"
          data-testid={`home-product-cards-section-card-subtitle-${cardTitleString}`}
        />
      )}
      {iconCardItem?.length > 0 && (
        <ul className={styles['icon-container']}>
          {iconCardItem.map((icon, index) => {
            return (
              <IconListItem
                key={`icon-list-item-${index}`}
                cardTitleString={cardTitleString}
                iconColor={icon.fields.iconColor}
                iconName={icon.fields.iconName}
                itemText={icon.fields.description}
                classNameIcon={styles['icon']}
              />
            )
          })}
        </ul>
      )}
      {cardText && (
        <Typography
          content={cardText}
          variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodySm' }}
          className={styles['card-copy-content']}
          fontWeight="regular"
          data-testid={`home-product-cards-section-card-text-${cardTitleString}`}
        />
      )}
      {secondaryCardLink && (
        <div className={styles['second-button-container']}>{linkOrDownload(secondaryCardLink)}</div>
      )}
      {cardLink && <div className={styles['button-container']}>{linkOrDownload(cardLink)}</div>}
    </Paper>
  )
}
