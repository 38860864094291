import classNames from 'classnames'
import styles from './PlayButtonBackgrounds.module.scss'
import { Image } from 'components/Image'

function PlayButtonBackground({ onClick, className, fontSize = 40 }) {
  return (
    <div className={classNames(styles['background-button'], className)} onClick={() => onClick?.()}>
      <Image alt={'Play Button'} height={fontSize} src="/play-btn.svg" width={fontSize} />
    </div>
  )
}

export { PlayButtonBackground }
