import React from 'react'
import classNames from 'classnames'
import { Button } from '@achieve/ascend'
import { Typography } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { LINK_UI_COMPONENTS } from 'constants/contentfulUiComponentTypes'
import { useConfig } from 'providers/AnalyticsProvider'
import auth0SignUp from 'utils/auth0SignUp/auth0SignUp'
import styles from './LinkButton.module.scss'

// Thes variants are tightly coupled to Button from `@achieve/ascend` and ideally they would be
// exposed with typescript. They should be kept in sync with variants for `Button` component from
// Ascend/MUI if those every get updated or additional variants are created for `Button`.
const LINK_BUTTON_VARIANTS = {
  TEXT: 'text',
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
}

const ButtonWithRef = React.forwardRef(
  (
    {
      onClick,
      passThroughOnClick = () => {},
      label,
      variant = LINK_BUTTON_VARIANTS.CONTAINED,
      color = 'primary',
      typographicOptions = {},
      className = '',
      ...restProps
    },
    // eslint complains that ref is unused, but React also complains that you
    // have to include a ref for a forwardRef render function
    ref // eslint-disable-line no-unused-vars
  ) => {
    const localClickHandler = (event) => {
      if (typeof onClick === 'function') {
        onClick?.(event)
      }
      passThroughOnClick?.(event)
    }

    return (
      <Button
        color={color}
        variant={variant}
        data-variant={variant}
        className={classNames(styles['base-styles'], className)}
        onClick={(event) => {
          localClickHandler(event)
        }}
        {...restProps}
      >
        <Typography content={label} {...typographicOptions} />
      </Button>
    )
  }
)

export const LinkButton = ({
  content,
  onClick,
  variant,
  color,
  typographicOptions,
  track,
  disabled = false,
  noLink,
  ...restProps
}) => {
  const { config = {} } = useConfig()
  if (!content?.fields && !content) return null

  const { uiComponent, linkText, linkHref } = content?.fields ? content.fields : content

  // Handle LinkAuth0 use case
  if (uiComponent === LINK_UI_COMPONENTS.LINK_AUTH0) {
    // Don't treat as a normal link with href
    noLink = true
    // override onClick with auth0 sign up
    onClick = () => auth0SignUp(config)
  }

  return (
    <AchieveLink
      href={disabled || noLink ? 'javascript:void(0)' : linkHref}
      passHref
      track={track}
      noLink
      withNextLink
      disabled={disabled}
    >
      <ButtonWithRef
        label={linkText}
        passThroughOnClick={onClick}
        variant={variant}
        color={color}
        typographicOptions={typographicOptions}
        disabled={disabled}
        {...restProps}
      />
    </AchieveLink>
  )
}

// Expose button variants to consumers
LinkButton.VARIANTS = LINK_BUTTON_VARIANTS

ButtonWithRef.displayName = 'LinkButton'
