import { pickAll } from 'ramda'

export const contentMap = (content = [], requestedFields = []) => {
  let fieldMap
  if (Array.isArray(content)) {
    fieldMap = content.reduce((accum, item) => {
      let fields = item?.fields ?? item
      if (!fields) return accum
      const name = fields?.fieldName ? fields.fieldName : Object.keys(fields)
      if (name) {
        accum[name] = fields
      }
      return accum
    }, {})
  } else {
    Object?.keys(content)?.forEach((key) => {
      if (!requestedFields.includes(key)) {
        delete content[key]
      }
    })
    fieldMap = content
  }
  return pickAll(requestedFields, fieldMap)
}
