import { Typography } from 'components/Contentful'
import styles from './CardText.module.css'

export const CardText = ({ content }) => {
  if (content === undefined || Object.keys(content).length === 0) return null

  const { cardTitleText, cardText } = content?.fields || {}

  return (
    <div className={styles.card}>
      {cardTitleText && <Typography content={cardTitleText} />}
      {cardText && <Typography content={cardText} />}
    </div>
  )
}
