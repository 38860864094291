import { addDefaultOrganicParams } from 'utils/queryManipulation'
import { DEV_URL, ENV_TO_URL } from 'constants/environments'
import Cookies from 'js-cookie'

/**
 * Authenticates a user with Auth0 and returns an Auth0 authentication result
 * @param {Object} config - The Auth0 configuration object
 * @returns {Object} The Auth0 authentication result
 */
const auth0SignUp = async (config) => {
  if (!config) return

  const auth0js = await import('auth0-js')

  const convertCharToHex = (char) => {
    const asciiCode = char.charCodeAt(0)
    const hexValue = asciiCode.toString(16)
    return hexValue
  }

  const encode = (str) => {
    const segments = []
    for (let i = 0; i < str.length; i++) {
      const char = str.charAt(i)
      // This is the regex from auth0 error /^[-\w.*~@+ /:]{1,255}$/
      if (char.match(/[-\w.*~@+ /:]/i)) {
        segments.push(char)
      } else {
        segments.push(`.${convertCharToHex(char)}.`)
      }
    }
    return segments.join('')
  }

  const auth0Client = new auth0js.WebAuth({
    domain: config.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientID: config.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    audience: config.NEXT_PUBLIC_AUTH0_AUDIENCE,
    scope: 'openid email name profile',
  })

  // Determine the base server URL
  const serverUrl = ENV_TO_URL[config?.NEXT_PUBLIC_APP_ENV] ?? DEV_URL

  // Get the current url, add default organic utm params and a specific utm_source for mxp
  let currentHref = window.location.href
  currentHref = addDefaultOrganicParams(currentHref)

  // Add an mxp specific utm_source if it does not already exist
  if (currentHref.indexOf('utm_source') === -1) {
    currentHref = `${currentHref}&utm_source=achieve_organic_mxp`
  }

  // Capture the current query string
  const currentUrl = new URL(currentHref)
  const queryString = currentUrl.search

  const baseSignUpHref = new URL(`${serverUrl}/dashboard/onboarding`).href

  const authorizeObj = {
    responseType: 'code',
    screen_hint: 'signup',

    // Add the captured query param string appended
    // We can pass as many query strings as we want here.
    redirectUri: `${baseSignUpHref}${queryString}`,
  }

  // Because ext- params can only be 255 chars, we have to store all the long
  // query string params in a cookie and grab them on auth-web
  const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000)
  const queryStringCookieName = `achieve_${Date.now()}`
  const cookieDomain = `.${window.location.host.split('.').slice(-2).join('.')}`
  Cookies.set(queryStringCookieName, queryString, {
    domain: cookieDomain,
    expires: inFifteenMinutes,
  })

  // Create the base redirect url
  const baseRedirectHref = new URL(`${serverUrl}/dashboard/onboarding`).href

  // Create the sign up override url with the `baseRedirectHref` as the url param value per Auth0
  // signin url override cookie name (suoqscn)
  // We pass the cookie name for the query strings so auth-web can get it
  const baseSignInOverrideUrl = new URL(`${serverUrl}/signin`)
  baseSignInOverrideUrl.searchParams.append('url', baseRedirectHref)
  baseSignInOverrideUrl.searchParams.append('suoqscn', queryStringCookieName)

  // You have to encode ext- params
  const extSigninUrlOverride = encode(baseSignInOverrideUrl.href)

  // ext- params can only be 255 chars.
  // Encode the final sign in override URL (encode required for Auth0)
  if (extSigninUrlOverride.length <= 255) {
    authorizeObj['ext-signin_url_override'] = extSigninUrlOverride
  } else {
    // Fallback
    // If this is over 255, it will break, but why would it be over 255.
    authorizeObj['ext-signin_url_override'] = encode(new URL(`${serverUrl}/signin`).href)
  }

  return auth0Client.authorize(authorizeObj)
}

export { auth0SignUp }
export default auth0SignUp
