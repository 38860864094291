import Container from '@mui/material/Container'
import classNames from 'classnames'
import styles from './SectionContainer.module.scss'

const SectionContainer = ({ className, children, narrow = true }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      className={classNames(styles['achieve-section-container'], className)}
      data-narrow={narrow}
    >
      {children}
    </Container>
  )
}

export default SectionContainer
