import { useEffect, useState } from 'react'

const useCurrentUrl = () => {
  const [currentURL, setCurrentURL] = useState(null)
  useEffect(() => {
    setCurrentURL(window.location.href)
  }, [])
  return currentURL
}

export { useCurrentUrl }
