import { Grid, Skeleton } from '@achieve/ascend'
import styles from './VideoPlayer.module.scss'

import React, { useReducer, useEffect, useState } from 'react'
import useViewportSize from 'hooks/useViewportSize'
import { videoMap } from 'constants/videoMappings'
import { TYPES_SOURCES_VIDEOS } from 'utils/shared'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'

import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useEventScope } from 'hooks/useEventScope'
import { EVENT_LIST } from 'utils/analytics/sync-event-list'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { MediaImageStandard } from 'components/Contentful'
import { VideoFrame } from './VideoFrame'
import { PlayBtn } from 'components/Svgs/PlayBtn'

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'initVideo':
      return { ...state, ...action.payload }
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoPlayer({
  content = {},
  maxWidth = false,
  frameClassName,
  frameEmbed = false,
  aspectRatio,
  trackOnPlay = {},
  extraPadding = 40,
  event,
  priority = false,
}) {
  const { source, autoplay, altText, thumbnail } = content

  const { breakpointLetterSize, calculatedClient, screenWidth } = useViewportSize()

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [heightAspectRadio, setHeightAspectRadio] = useState(
    videoMap[breakpointLetterSize].height - extraPadding
  )

  const specificity = useEventScope()
  const { fireAndForget } = useTriggerEvents()

  const [state, dispatch] = useReducer(reducerFunction, {
    typeVideo: null,
    showVideo: false,
    clickToPlay: false,
    dimension: {
      height: videoMap[breakpointLetterSize].height,
      width: videoMap[breakpointLetterSize].width - extraPadding,
    },
  })
  // use 40 to fix videoplayer padding on about us page
  const mobileWidth = maxWidth ? screenWidth : screenWidth - extraPadding

  useEffect(() => {
    var videoHandler = false
    if (document.readyState !== 'loading') {
      start()
    } else {
      videoHandler = true
      document.addEventListener('DOMContentLoaded', start)
    }
    function start() {
      if (videoHandler) {
        document.removeEventListener('DOMContentLoaded', start)
      }
      dispatch({
        type: 'initVideo',
        payload: {
          showVideo: true,
        },
      })
    }
  }, [])

  useEffect(() => {
    setHeightAspectRadio(isMobile ? mobileWidth / aspectRatio : state.dimension.width / aspectRatio)
  }, [isMobile, mobileWidth, state.dimension.width, aspectRatio])

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          dimension: {
            height: videoMap[breakpointLetterSize].height,
            width: videoMap[breakpointLetterSize].width - extraPadding,
          },
        },
      })
    }
  }, [calculatedClient, breakpointLetterSize, extraPadding])

  useEffect(() => {
    if (source) {
      dispatch({
        type: 'initVideo',
        payload: {
          typeVideo: TYPES_SOURCES_VIDEOS.youtube,
        },
      })
    }
  }, [source])

  const handleEvent = (eventname) => {
    switch (eventname) {
      case 'playing':
        return fireAndForget(
          updateTrackWithListedEvent(
            {
              ...{
                ...trackOnPlay,
                list_name: EVENT_LIST.VIDEO_PLAYER_PLAY_INITIATED,
              },
              ...event,
            },
            specificity
          )
        )
      case 'pause':
        return fireAndForget(
          updateTrackWithListedEvent(
            {
              ...{
                ...trackOnPlay,
                list_name: EVENT_LIST.VIDEO_PLAYER_PLAY_INITIATED,
              },
              ...event,
            },
            specificity
          )
        )
      case 'end':
        return fireAndForget(
          updateTrackWithListedEvent(
            {
              ...{
                ...trackOnPlay,
                list_name: EVENT_LIST.VIDEO_PLAYER_PLAY_INITIATED,
              },
              ...event,
            },
            specificity
          )
        )
      default:
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleEvent)

    return () => {
      window.removeEventListener('message', handleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const opts = {
    height: !frameEmbed && state.dimension.height,
    width: !frameEmbed && (isMobile ? mobileWidth : state.dimension.width),
    playerVars: {
      playsinline: 0,
      autoplay: autoplay ? 1 : 0,
      mute: isMobile ? 1 : 0,
    },
  }

  const autoOpts = {
    height: !frameEmbed && state.dimension.height,
    width: !frameEmbed && (isMobile ? mobileWidth : state.dimension.width),
    playerVars: {
      playsinline: 0,
      autoplay: 1,
      mute: isMobile ? 1 : 0,
    },
  }

  let paddingBottom
  if (aspectRatio) paddingBottom = `${(1 / aspectRatio) * 100}%`

  return (
    <>
      <Grid
        data-testid="iframe-container"
        style={
          frameClassName
            ? {}
            : {
                height:
                  state.typeVideo === TYPES_SOURCES_VIDEOS.youtube
                    ? 'fit-content'
                    : aspectRatio
                    ? heightAspectRadio
                    : state.dimension.height,
                width: isMobile ? mobileWidth : state.dimension.width,
                margin: 'auto',
              }
        }
        className={frameClassName}
        container
      >
        <div
          data-testid="iframe-container"
          className={frameClassName ? styles['iframe-wrapper-external'] : styles['iframe-wrapper']}
        >
          {!state.showVideo && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={
                state.typeVideo === TYPES_SOURCES_VIDEOS.youtube
                  ? 'fit-content'
                  : aspectRatio
                  ? heightAspectRadio
                  : state.dimension.height
              }
            />
          )}
          {state.showVideo ? (
            <>
              {state.typeVideo === TYPES_SOURCES_VIDEOS.youtube && (
                <>
                  {!thumbnail ? (
                    <VideoFrame
                      frameEmbed={frameEmbed}
                      source={source}
                      altText={altText}
                      opts={opts}
                      handleEvent={handleEvent}
                    />
                  ) : !state.clickToPlay ? (
                    <div
                      className={styles['thumbnail-container']}
                      onClick={() => {
                        dispatch({
                          type: 'initVideo',
                          payload: {
                            clickToPlay: true,
                          },
                        })
                      }}
                    >
                      <div className={styles['play-button']}>
                        <PlayBtn title={'Play Button'} height="100%" width="100%" />
                      </div>
                      <MediaImageStandard
                        alt={altText}
                        content={thumbnail}
                        data-testid="video-thumbnail"
                        aspectRatio={aspectRatio}
                        priority={priority}
                      />
                    </div>
                  ) : (
                    <div
                      className={styles['thumbnail-container']}
                      style={{ width: '100%', height: 0, paddingBottom }}
                    >
                      {/* width 0 skeleton prevents cls*/}
                      <Skeleton
                        variant="rectangular"
                        width={0}
                        height={aspectRatio ? heightAspectRadio : state.dimension.height}
                      />
                      <div
                        style={{
                          marginTop: aspectRatio ? -heightAspectRadio : -state.dimension.height,
                        }}
                      >
                        <VideoFrame
                          frameEmbed={frameEmbed}
                          source={source}
                          altText={altText}
                          opts={autoOpts}
                          handleEvent={handleEvent}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          ) : null}
        </div>
      </Grid>
    </>
  )
}
export { VideoPlayer }
