/**
 * Mappings for Contentful uiComponent fields
 *
 * The purpose of these constants are to provide documentation around reusable contentful content
 * types that have multiple `uiComponent` options. Currently, some components have the `uiComponent`
 * field but the code doesn't always leverage them. But for the purposes where we do want to use
 * that field, we should provide constants that match their names in Contentful `uiComponent` field.
 */
export const LINK_UI_COMPONENTS = {
  LINK: 'Link',
  LINK_BUTTON: 'LinkButton',
  LINK_TO_SECTION: 'LinkToSection',
  LINK_AUTH0: 'LinkAuth0',
}

export const TYPENAME_PAGE_SECTION = 'PageSection'
export const TYPENAME_SYS = 'Sys'
export const TYPENAME_PAGE_SECTION_RELATED_CONTENT_COLLECTION =
  'PageSectionRelatedContentCollection'
export const TYPENAME_CONTENT_TYPE_RICH_TEXT = 'ContentTypeRichText'
export const TYPENAME_CONTENT_TYPE_RICH_TEXT_TEXT_CONTENT = 'ContentTypeRichTextTextContent'
export const TYPENAME_STANDARD_CARD_ICON_CARD_ITEM_COLLECTION = 'StandardCardIconCardItemCollection'
export const TYPENAME_STANDARD_CARD_EXTRA_COMPONENTS_COLLECTION =
  'StandardCardExtraComponentsCollection'
export const TYPENAME_STANDARD_CARD = 'StandardCard'
export const TYPENAME_ASSET = 'Asset'
export const TYPENAME_GRID = 'Grid'
export const TYPENAME_GRID_GRID_ITEMS_COLLECTION = 'GridGridItemsCollection'
export const TYPENAME_STANDARD_CARD_TITLE_TEXT = 'StandardCardCardTitleText'
export const TYPENAME_STANDARD_CARD_TEXT = 'StandardCardCardText'
export const TYPENAME_SECTION_THEME = 'SectionTheme'
export const TYPENAME_JSON_FIELD = 'JsonField'
export const TYPENAME_CONTENT_TYPE_RICH_TEXT_TEXT_CONTENT_LINKS =
  'ContentTypeRichTextTextContentLinks'
export const TYPENAME_ARTICLE = 'Article'
export const TYPENAME_MEDIA_FIELD = 'MediaField'
export const TYPENAME_AUTHOR_NAME = 'AuthorName'
export const TYPENAME_CONTENT_TYPE_RICH_TEXT_TEXT_CONTENT_ENTRIES =
  'ContentTypeRichTextTextContentEntries'
export const TYPENAME_CONTENT_TYPE_RICH_TEXT_TEXT_CONTENT_ASSETS =
  'ContentTypeRichTextTextContentAssets'
