import dynamic from 'next/dynamic'
const YouTube = dynamic(() => import('react-youtube'))
import styles from './VideoPlayer.module.scss'
import useOnScreen from 'hooks/useOnScreen'

const getVideoId = (src) => {
  return src.split('?')[0].split('/').pop()
}

function VideoFrame({ frameEmbed, source, altText, opts, handleEvent }) {
  const { ref, isIntersecting } = useOnScreen()
  return (
    <div ref={ref} className={frameEmbed && styles['video-player-embed']}>
      {isIntersecting && (
        <YouTube
          playing={true}
          videoId={getVideoId(source)}
          opts={opts}
          style={{ border: 0 }}
          title={altText}
          onPlay={() => {
            handleEvent('playing')
          }}
          onPause={() => {
            handleEvent('pause')
          }}
          onEnd={() => {
            handleEvent('end')
          }}
        />
      )}
    </div>
  )
}

export { VideoFrame }
