import styles from './Background.module.css'
import Section from 'components/Section'
import { rewriteAssetUrl } from 'utils/conversions/urls'

export function Background({ children, imageContent }) {
  const url = rewriteAssetUrl(imageContent?.fields?.file.url)
  const localStyling = {
    backgroundImage: `url(${url})`,
  }

  return (
    <Section style={url ? localStyling : {}} className={styles['background-section']}>
      {children}
    </Section>
  )
}
