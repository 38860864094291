import * as React from 'react'
export function Ship({ title = 'Ship', className, ...props }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <title>{title}</title>
      <g clipPath="url(#clip0_3865_10544)">
        <path
          d="M11.1053 8.89537L4.53512 15.4655M5.24418 10.6973L2.02601 9.93712C1.67195 9.85348 1.54742 9.41392 1.80391 9.15651L4.3632 6.59721C4.53512 6.42529 4.76838 6.32679 5.01279 6.32493L7.89826 6.29984M11.5328 2.99803C13.6804 4.46261 15.538 6.32028 17.0026 8.4679M9.30244 14.7565L10.0626 17.9746C10.1462 18.3287 10.5858 18.4532 10.8432 18.1967L13.4025 15.6374C13.5744 15.4655 13.6729 15.2323 13.6748 14.9879L13.6999 12.1024M17.5537 6.95778L18.3046 3.34466C18.509 2.36146 17.6392 1.49164 16.656 1.69608L13.0429 2.44696C11.9788 2.66813 11.0031 3.19504 10.2355 3.96358L7.27098 6.92712C6.14003 8.05807 5.40402 9.52451 5.1717 11.1071L5.16147 11.1749C5.01464 12.1851 5.35291 13.2045 6.07405 13.9266C6.79518 14.6477 7.81555 14.986 8.8257 14.8382L8.89354 14.828C10.4761 14.5966 11.9426 13.8597 13.0735 12.7287L16.0371 9.7652C16.8056 8.9976 17.3325 8.02183 17.5537 6.95778Z"
          stroke="#2C47F6"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3865_10544">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default Ship
