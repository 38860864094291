import * as React from 'react'
export function Check({ title = 'Check', className, ...props }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <title>{title}</title>
      <rect
        x="0.625"
        y="0.625"
        width="18.75"
        height="18.75"
        rx="9.375"
        stroke="#2C47F6"
        strokeWidth="1.25"
      />
      <path
        d="M14.4436 6.66797L8.33247 12.7791L5.55469 10.0013"
        stroke="#2C47F6"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default Check
